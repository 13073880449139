/* Dropdown Button */
.dropbtn {
  background-color: transparent;
  color: white;
  padding: 0.5rem 0.5rem;
  font-size: 18px;
  border: none;
  border-radius: 5px; 
}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
  width: fit-content;
 
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  box-shadow: 0px 8px 12px 2px rgba(0,0,0,0.2);
  border-radius: 5px;
  z-index: 1;
}

/* Links inside the dropdown */
.dropdown-content a {
  color: black;
  padding: 6px 12px;
  width: 200px;
  text-decoration: none;
  display: block;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #ddd;width: 200px;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block; width: 200px;}

/* Change the background color of the dropdown button when the dropdown content is shown */
.dropdown:hover .dropbtn {background-color: #ddd; color: #008069; width: 160px;}