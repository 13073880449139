/* .container{
    width:max-content;
    background-color: rgba(0, 0, 0, 0.5);
    color: black;
} */
.box{
  background-color: rgba(0, 0, 0, 0.5);
  color: black;
  width:1320px; 
}

@media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
}
