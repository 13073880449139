:root {
    --primary: #fff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 2px;
    outline: none;
    border: none;
    cursor: pointer;
  }
  
  .btn--primary {
    background-color: var(--primary);
    color: #242424;
    border: 1px solid var(--primary);
  }
  
  .btn--outline {
    background-color: transparent;
    color: #fff;
    padding: 8px 20px;
    border: 1px solid var(--primary);
    transition: all 0.3s ease-out;
  }
  
  .btn--medium {
    padding: 8px 20px;
    font-size: 18px;
  }
  
  .btn--large {
    padding: 12px 26px;
    font-size: 20px;
  }
  
  .btn--large:hover,
  .btn--medium:hover {
    transition: all 0.3s ease-out;
    background: #fff;
    color: #242424;
    transition: 250ms;
  }

  .row{
      display:flex
      
  }

  .main__cards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    gap: 30px;
    margin: 20px 0;
    padding: 10px;
}

.behave__cards {
    display: flex;
    
    margin: 20px 0;
}

.card {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 140px;
    width: auto;
    padding: 25px;
    border-radius: 5px;
    background: #ffffff;
    box-shadow: 5px 5px 13px #ededed, -5px -5px 13px;
}

@media screen and (max-width:960px) {
  .main__cards {
      display: flex;
      flex-direction: column;
      width: auto;
      gap: 30px;
      margin: 80px 0;
      padding: 60px
  }

}
  