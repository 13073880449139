body {
  background: radial-gradient(#e5e5e5, #ffff, #efefef);
}

.card{
  width: 20rem;

}

.card:hover {
  box-shadow: 20px 20px 10px rgba(0,0,0,0.4);
}

.card-body {
  padding: 3rem 0 !important;
}

.card-text {
  padding: 1rem 0 !important;
}

.card-text{
  font-size: 1rem;
  padding: 0.4rem 1.9rem;
}

.container-fluid .row{
  padding-top: 6rem;
}

.overflow {
  overflow: hidden;
}

.card-img-top {
transform: scale(1);
transition: transform 0.5s ease;
}

.card-img-top:hover {
  transform: scale(1.8);
  }


